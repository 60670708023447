import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { clientsFetchRequest } from "../actions";
import { usersFetchRequest } from "../../Users/actions";
import { Loader } from "../../../Common/Loader";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
  Divider,
  Select,
  Row,
  Col
} from "antd";
import style from "./index.module.scss";
import { api } from "../../../../helpers/api";
import { getFullDate } from "../../../../helpers/_helper";

const { Option } = Select;

export const Clients = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [clientList, setClientsList] = useState([]);
  const [isModalVisibleClient, setIsModalVisibleClient] = useState(false);
  const [clientDetails, setClientDetails] = useState({});

  useEffect(() => {
    props.clientsFetchRequest();
    // eslint-disable-next-line
  }, []);

  const showAddModal = () => {
    setIsModalVisible(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        if (editId) {
          await api("put", `clients/${editId}`, values);
          setEditId(null);
        } else await api("post", "clients", values);
        props.clientsFetchRequest();
        setIsModalVisible(false);
      }
    });
  };

  const handleDelete = async (key) => {
    await api("delete", `clients/${key}`);
    props.clientsFetchRequest();
  };

  const handleEdit = (values) => {
    const dob = new moment(values.dob);
    setIsModalVisible(true);
    setEditId(values._id);
    props.form.setFieldsValue({ ...values, dob });
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setIsModalVisibleClient(false);
  };

  const sorting = (data) => {
    const myData = data.sort((a, b) => {
      let fa = a.name?.toLowerCase(),
        fb = b.name?.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return myData;
  };

  const handleClient = (value) => {
    setClientDetails(value);
    setIsModalVisibleClient(true);
  };

  const handleClientChange = async (value) => {
    if (value !== "") {
      const clients = value && (await api("get", `/search/clients/${value}`));
      const SortedClients = sorting(clients);
      setClientsList(SortedClients);
    } else {
      const data = await api("get", "clients");
      const SortedClients = sorting(data);
      setClientsList(SortedClients);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Sur Name",
      dataIndex: "surName",
      key: "surName"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => getFullDate(date)
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => getFullDate(date)
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record._id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      )
    }
  ];
  const { getFieldDecorator } = props.form;
  return (
    <div>
      <h1>Clients</h1>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32
        }}
      >
        <Col xs={12} sm={8} md={3} lg={3} xl={3}>
          <Button type="primary" onClick={showAddModal}>
            Add Client
          </Button>
        </Col>
        <Col xs={12} sm={10} md={6} lg={6} xl={6}>
          <Select
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            placeholder="Search Client"
            style={{ width: "20vw" }}
            onSearch={handleClientChange}
          >
            {clientList.map((client) => (
              <Option
                key={client._id}
                // style={{ color: d.color }}
                onClick={() => handleClient(client)}
              >
                {client.name} - {client.surName}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <div className={style.clientsList}>
        <Loader isLoading={props.isLoading}>
          <Table
            columns={columns}
            dataSource={props.clients}
            rowKey="_id"
            scroll={{
              x: 600
            }}
          />
        </Loader>
      </div>
      <Modal
        title="Add Client"
        visible={isModalVisible}
        footer=""
        onCancel={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Please input client name!" }]
            })(<Input placeholder="Client Name" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("surName", {
              rules: [
                { required: true, message: "Please input client surname!" }
              ]
            })(<Input placeholder="Sur Name" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Please input client email!" }]
            })(<Input placeholder="Email" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("contact", {
              rules: [
                { required: true, message: "Please input client contact!" }
              ]
            })(<Input placeholder="Contact number" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("notes")(<Input placeholder="Notes" />)}
          </Form.Item>
          ;
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Client Information"
        visible={isModalVisibleClient}
        footer=""
        onCancel={handleClose}
      >
        <p>Client Name : {clientDetails?.name} </p>
        <p>Client Surname : {clientDetails?.surName} </p>
        <p>Client Contact Number : {clientDetails?.contact} </p>
        <p>Client Email : {clientDetails?.email}</p>{" "}
      </Modal>
    </div>
  );
};
const WrappedForm = Form.create({ name: "horizontal_login" })(Clients);

Clients.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  clients: PropTypes.array,
  users: PropTypes.array,
  clientsFetchRequest: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isLoading: state.cabinet.studios.isLoading,
  clients: state.cabinet.clients.data,
  users: state.cabinet.users.data
});

export const ClientsContainer = withRouter(
  connect(mapStateToProps, { clientsFetchRequest, usersFetchRequest })(
    WrappedForm
  )
);
