import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { usersFetchRequest } from "../actions";
import { Loader } from "../../../Common/Loader";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
  Divider,
  Select,
} from "antd";
import style from "./index.module.scss";
import { api } from "../../../../helpers/api";
import { getFullDate } from "../../../../helpers/_helper";

export const Users = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    props.usersFetchRequest();
    // eslint-disable-next-line
  }, []);

  const showAddModal = () => {
    setIsModalVisible(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        if (editId) {
          await api("put", `users/${editId}`, values);
          setEditId(null);
        } else await api("post", "users", values);
        props.usersFetchRequest();
        setIsModalVisible(false);
      }
    });
  };

  const handleDelete = async (key) => {
    await api("delete", `users/${key}`);
    props.usersFetchRequest();
  };

  const handleEdit = (values) => {
    setIsModalVisible(true);
    setEditId(values._id);
    props.form.setFieldsValue(values);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => getFullDate(date),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => getFullDate(date),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record._id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];
  const { getFieldDecorator } = props.form;

  return (
    <div>
      <h1>Users</h1>
      <Button type="primary" onClick={showAddModal}>
        Add User
      </Button>
      <div className={style.usersList}>
        <Loader isLoading={props.isLoading}>
          <Table columns={columns} dataSource={props.users} rowKey="_id" scroll={{
      x: 600,
    }} />
        </Loader>
      </div>

      <Modal
        title="Add user"
        visible={isModalVisible}
        footer=""
        onCancel={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator("firstName", {
              rules: [
                { required: true, message: "Please input user's first name!" },
              ],
            })(<Input placeholder="First Name" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("lastName", {
              rules: [
                { required: true, message: "Please input user's last name!" },
              ],
            })(<Input placeholder="Last Name" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "Please input user's email!" },
              ],
            })(<Input placeholder="Email" />)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator("type", {
              rules: [
                {
                  required: true,
                  message: "Please input user's role!",
                },
              ],
            })(
              <Select placeholder="Select User Type">
                <Select.Option value="admin">Admin</Select.Option>
                <Select.Option value="employee">Employee</Select.Option>
                <Select.Option value="worker">Worker</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Please choose a color for this user (will be displayed on calendar)" className={style.colorPicker}>
            {getFieldDecorator("color", {
              rules: [
                { required: true, message: "Please input user's color!" },
              ],
            })(<Input type="color" />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
const WrappedForm = Form.create({ name: "horizontal_login" })(Users);

Users.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.array,
  usersFetchRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.cabinet.users.isLoading,
  users: state.cabinet.users.data,
});

export const UsersContainer = withRouter(
  connect(mapStateToProps, { usersFetchRequest })(WrappedForm)
);
