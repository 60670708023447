import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { usersFetchRequest } from "../actions";
import { Loader } from "../../../Common/Loader";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
  Divider,
  Select,
  DatePicker,
} from "antd";
import style from "./index.module.scss";
import { api } from "../../../../helpers/api";
import { getFullDate } from "../../../../helpers/_helper";

export const Projects = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(async () => {
    props.usersFetchRequest();
    // eslint-disable-next-line
  }, []);

  const showAddModal = async () => {
    setIsModalVisible(true);

    const users = await api("get", `users`);
    const projects = await api("get", `projects`);

    setEmployees(users);
    setProjects(projects);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        if (editId) {
          await api("put", `worklogs/${editId}`, values);
          setEditId(null);
        } else await api("post", "worklogs", values);
        props.usersFetchRequest();
        setIsModalVisible(false);
      }
    });
  };

  const handleDelete = async (key) => {
    await api("delete", `worklogs/${key}`);
    props.usersFetchRequest();
  };

  const handleEdit = (values) => {
    const startTime = new moment(values.startTime);
    const endTime = new moment(values.endTime);
    const employeeId = values.employeeId.name;
    const projectId = values.projectId.name;
    setIsModalVisible(true);
    setEditId(values._id);
    props.form.setFieldsValue({
      ...values,
      startTime,
      endTime,
      employeeId,
      projectId,
    });
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Employee",
      dataIndex: "employeeId.name",
      key: "employeeId._id",
    },
    {
      title: "Project",
      dataIndex: "projectId.name",
      key: "projectId._id",
    },
    {
      title: "Total Hours",
      dataIndex: "totalHours",
      key: "totalHours",
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => getFullDate(date),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => getFullDate(date),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record._id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];
  const { getFieldDecorator } = props.form;

  return (
    <div>
      <h1>Work Logs</h1>
      <Button type="primary" onClick={showAddModal}>
        Add Work Log
      </Button>
      <div className={style.usersList}>
        <Loader isLoading={props.isLoading}>
          <Table columns={columns} dataSource={props.users} rowKey="_id" />
        </Loader>
      </div>

      <Modal
        title="Add WorkLog"
        visible={isModalVisible}
        footer=""
        onCancel={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator("startTime", {
              rules: [{ required: true, message: "Please input start time!" }],
            })(
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="Start Time"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("endTime", {
              rules: [{ required: true, message: "Please input end time!" }],
            })(
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="End Time"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("totalHours", {
              rules: [
                { required: true, message: "Please input total no.of hours!" },
              ],
            })(<Input placeholder="Total Hours" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("employeeId", {
              rules: [
                {
                  required: true,
                  message: "Please select employee!",
                },
              ],
            })(
              <Select placeholder="Select Employee">
                {employees &&
                  employees.map(function (object, i) {
                    return (
                      <Select.Option value={object._id}>
                        {object.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("projectId", {
              rules: [
                {
                  required: true,
                  message: "Please select project!",
                },
              ],
            })(
              <Select placeholder="Select Project">
                {projects &&
                  projects.map(function (object, i) {
                    return (
                      <Select.Option value={object._id}>
                        {object.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
const WrappedForm = Form.create({ name: "horizontal_login" })(Projects);

Projects.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.array,
  usersFetchRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.cabinet.users.isLoading,
  users: state.cabinet.users.data,
});

export const WorkLogsContainer = withRouter(
  connect(mapStateToProps, { usersFetchRequest })(WrappedForm)
);
