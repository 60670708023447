import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { usersFetchRequest } from "../actions";
import { Loader } from "../../../Common/Loader";
import { Table, Button, Modal, Form, Input, Popconfirm, Divider } from "antd";
import style from "./index.module.scss";
import { api } from "../../../../helpers/api";
import { getFullDate } from "../../../../helpers/_helper";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const Projects = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    props.usersFetchRequest();
    // eslint-disable-next-line
  }, []);

  const exportPdf = () => {
    html2canvas(document.querySelector("#employeeReport"), {
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("employeeReport").style.display = "block";
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("total-time-spent-per-employee-per-project.pdf");
    });
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employee.name",
      key: "employee._id",
    },
    {
      title: "Project Name",
      dataIndex: "project.name",
      key: "project._id",
    },
    {
      title: "Total Hours Spent",
      dataIndex: "totalHours",
      key: "totalHours",
    },
  ];

  return (
    <div>
      <h1>Total time spent per employee, per project</h1>
      <Button type="primary" onClick={exportPdf}>
        Export in PDF format
      </Button>
      <div className={style.usersList}>
        <Loader isLoading={props.isLoading}>
          <Table
            columns={columns}
            dataSource={props.users}
            rowKey="_id"
            pagination={false}
          />
        </Loader>
      </div>
      <div id="employeeReport" className={style.employeeReport}>
        <h1>Total time spent per employee, per project</h1>
        <div className={style.usersList}>
          <Loader isLoading={props.isLoading}>
            <Table
              columns={columns}
              dataSource={props.users}
              rowKey="_id"
              pagination={false}
            />
          </Loader>
        </div>
      </div>
    </div>
  );
};

Projects.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.array,
  usersFetchRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.cabinet.users.isLoading,
  users: state.cabinet.users.data,
});

export const ReportsContainer = withRouter(
  connect(mapStateToProps, { usersFetchRequest })(Projects)
);
