const common = {};

const dev = {
  LOGGER_ENABLE: true,
  // API_BASE_URL: "http://15.160.215.17:3003/api/",
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
};
// const dev = {
//   LOGGER_ENABLE: true,
//   API_BASE_URL: "http://localhost:3003/api/",
// };

const prod = {
  LOGGER_ENABLE: false,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
};

const config = process.env.REACT_APP_NODE_ENV === "production" ? prod : dev;

export default {
  ...common,
  ...config,
};
