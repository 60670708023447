import { HomeContainer } from "./Home";
import { UsersContainer } from "./Users";
import { ProjectsContainer } from "./Projects";
import { WorkLogsContainer } from "./WorkLogs";
import { ReportsContainer } from "./Reports";
import { StudiosContainer } from "./Studios";
import { ClientsContainer } from "./Clients";
import { SlotContainer } from "./Slots";

export const routes = [
  {
    path: "/home",
    component: HomeContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/users",
    component: UsersContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/studios",
    component: StudiosContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/slots",
    component: SlotContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/clients",
    component: ClientsContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/projects",
    component: ProjectsContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/worklogs",
    component: WorkLogsContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/reports",
    component: ReportsContainer,
    isAuth: true,
    exact: true,
  },
];
