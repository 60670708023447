import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";

import { Loader } from "../../../Common/Loader";
import PropTypes from "prop-types";
import { api } from "../../../../helpers/api";
import { connect } from "react-redux";
import { getFullDate } from "../../../../helpers/_helper";
import moment from "moment";
import { studiosFetchRequest } from "../actions";
import style from "./index.module.scss";
import { usersFetchRequest } from "../../Users/actions";
import { withRouter } from "react-router-dom";

export const Studios = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);

  const studiosFetchRequest = () => async () => {
    try {
      const data = await api("get", "studios");
    } catch (error) { }
  };

  const usersFetchRequest = () => async () => {
    try {
      const data = await api("get", "studios");
    } catch (error) { }
  };

  useEffect(() => {
    props.studiosFetchRequest();
    props.usersFetchRequest();
    // eslint-disable-next-line
  }, []);

  const showAddModal = () => {
    setIsModalVisible(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        if (editId) {
          await api("put", `studios/${editId}`, values);
          setEditId(null);
        } else await api("post", "studios", values);
        props.studiosFetchRequest();
        setIsModalVisible(false);
      }
    });
  };

  const handleDelete = async (key) => {
    await api("delete", `studios/${key}`);
    props.studiosFetchRequest();
  };

  const handleEdit = (values) => {
    setIsModalVisible(true);
    setEditId(values._id);
    values.users = values.users.map(e => e._id)
    props.form.setFieldsValue(values);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Slots",
      dataIndex: "slots",
      key: "slots",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => getFullDate(date),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => getFullDate(date),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record._id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];
  const { getFieldDecorator } = props.form;

  return (
    <div>
      <h1>Studios</h1>
      <Button type="primary" onClick={showAddModal}>
        Add Studio
      </Button>
      <div className={style.studiosList}>
        <Loader isLoading={props.isLoading}>
          <Table columns={columns} dataSource={props.studios} rowKey="_id" scroll={{
      x: 600,
    }} />
        </Loader>
      </div>

      <Modal
        title="Add Studio"
        visible={isModalVisible}
        footer=""
        onCancel={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Please input studio's name!",
                },
              ],
            })(<Input placeholder="Name" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("slots", {
              rules: [
                {
                  required: true,
                  message: "Please input slots!",
                },
              ],
            })(<Input placeholder="Slots" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("users", {
              rules: [
                {
                  required: true,
                  message: "Please select users!",
                },
              ],
            })(
              <Select mode="multiple" placeholder="Select Users">
                {props.users.map((user) => (
                  <Select.Option value={user._id} key={user._id}>
                    {user.firstName + " " + user.lastName}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
const WrappedForm = Form.create({ name: "horizontal_login" })(Studios);

Studios.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  studios: PropTypes.array,
  users: PropTypes.array,
  studiosFetchRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.cabinet.studios.isLoading,
  studios: state.cabinet.studios.data,
  users: state.cabinet.users.data,
});

export const StudiosContainer = withRouter(
  connect(mapStateToProps, { studiosFetchRequest, usersFetchRequest })(
    WrappedForm
  )
);
