import { combineReducers } from "redux";

import { usersReducer as users } from "./Users";
import { studiosReducer as studios } from "./Studios";
import { clientsReducer as clients } from "./Clients";

export const reducers = combineReducers({
  users,
  studios,
  clients
});
