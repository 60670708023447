import React from "react";
import { Layout, Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// import style from "./index.module.scss";
const { SubMenu } = Menu;
const { Header: AntHeader } = Layout;

const HeaderComponent = ({ user, logout, menuList }) => {
  return (
    <AntHeader
      style={{
        padding: "0 24px",
        backgroundColor: "#333",
        display: "flex",
      }}
    >
      <div>
        <img
          src="/img/yourlogo.png"
          alt=""
          width="55px"
          style={{ marginLeft: "40px" }}
        />
      </div>

      <Menu
        mode="horizontal"
        style={{
          lineHeight: "64px",
          color: "white",
          flex: "auto",
          backgroundColor: "#333",
          textAlign: "center",
        }}
      >
        {menuList.map((item, i) => {
          return (
            <Menu.Item key={i}>
              <Link style={{color: "white"}} to={item.path}>
                <span>{item.label}</span>
              </Link>
            </Menu.Item>
          );
        })}

        <SubMenu
          style={{ float: "right" }}
          key="username"
          title={
            <span>
              <Icon type="user" />
              {user.email}
            </span>
          }
        >
          <Menu.Item onClick={() => logout()} key="1">
            Logout
          </Menu.Item>
        </SubMenu>
      </Menu>
    </AntHeader>
  );
};

HeaderComponent.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  menuList: PropTypes.array,
};

export const Header = HeaderComponent;
